import React, { useEffect } from "react"
import { navigate } from "gatsby"

const BlogRedirectTemplate = ({ pageContext: { slug } }) => {
  useEffect(() => {
    navigate(`/resources/blog/${slug}`)
  }, [slug])

  return <></>
}

export default BlogRedirectTemplate
